<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>품목별통계</h1></div>
    <common-area-search
        :row="2"
        :colspan="2"
        @search="allApiCall"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              @change="value=>{
                  propsCmpyCd = value
                  $refs.itemCdRef.widget().value('')
                  allApiCall()
                }"
              :params="{ 'sort[0].dir': 'asc', 'sort[0].field': 'cmpyNm', searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
              :default-index="0"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>조회일</label>
        <ul>
          <kendo-datepicker
              ref="dtRef"
              :value="new Date()"
              :disabled="true"
              :format="'yyyy/MM/dd'"
              :placeholder="'년/월/일'"
              @change="allApiCall"
          ></kendo-datepicker>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>품목</label>
        <ul>
          <common-multi-drop-down
              ref="itemCdRef"
              :params="{ searchCmpyCd : propsCmpyCd }"
              :all-params-validator="true"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/item`"
              :data-value-field="'itemCd'"
              :data-text-field="'itemNm'"
              @change="allApiCall"
          >
          </common-multi-drop-down>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->
    <div class="box_flex">
    <div class="contents_wrap mgt10">
       <div class="small_title_wrap w100">
         <h2>입고수</h2>
        </div>
            <kendo-chart :render-as="'canvas'"
                        :chart-area="{background : '', height:270, margin: { top : 10, left : 0, right :0, bottom:10 }}"
                        :legend-item-click="onClickLegend"
                        :pannable-lock="'y'"
                        :transitions="inTransitions"
                        :series="[
                          {
                            type: inRetChartData.series.type,
                            data: inRetChartData.series.data,
                            axis: inRetChartData.series.axis,
                            visible : inRetChartData.series.visible,
                            categoryField: inRetChartData.series.categoryField,
                            name: constant.inRet,
                            field : inRetChartData.series.field,
                            color: inRetChartData.series.color,
                            tooltip : inRetChartData.series.tooltip
                          },
                          {
                            type: inNewChartData.series.type,
                            data: inNewChartData.series.data,
                            axis: inNewChartData.series.axis,
                            visible : inNewChartData.series.visible,
                            categoryField: inNewChartData.series.categoryField,
                            name: constant.inNew,
                            field : inNewChartData.series.field,
                            color: inNewChartData.series.color,
                            tooltip : inNewChartData.series.tooltip
                          }]"
                        :category-axis="{
                          justified: true,
                          axisCrossingValues: [0, 999, 0],
                        }"
                        :value-axis="[
                          inRetChartData.valueAxis,
                          inNewChartData.valueAxis
                        ]"
                        :legend="{
                          visible : true,
                          position : 'top',
                        }"
                        :theme="'sass'">
            </kendo-chart>
    </div>

    <div class="contents_wrap mgt10">
       <div class="small_title_wrap w100">
         <h2>출고수</h2>
        </div>
        <div class="box_flex w100">
            <kendo-chart :render-as="'canvas'"
                        :chart-area="{background : '', height:270, margin: { top : 10, left : 0, right :0, bottom:10 }}"
                        :series="[{
                            type: outChartData.series.type,
                            data: outChartData.series.data,
                            axis: outChartData.series.axis,
                            visible : outChartData.series.visible,
                            categoryField: outChartData.series.categoryField,
                            field : outChartData.series.field,
                            color: outChartData.series.color,
                            tooltip : outChartData.series.tooltip
                          }]"
                        :category-axis="{
                          justified: true,
                        }"
                        :value-axis="[outChartData.valueAxis]"
                        :legend-visible="false"
                        :theme="'sass'">
            </kendo-chart>
        </div>
    </div>

    <div class="contents_wrap mgt10">
       <div class="small_title_wrap w100">
         <h2>재고변동</h2>
        </div>
      <div class="box_flex w100">
          <div>
            <kendo-chart :render-as="'canvas'"
                        :chart-area="{background : '', height:270, margin: { top : 10, left : 0, right :0, bottom:10 }}"
                        :series="[{
                            type: stkChartData.series.type,
                            data: stkChartData.series.data,
                            axis: stkChartData.series.axis,
                            visible : stkChartData.series.visible,
                            categoryField: stkChartData.series.categoryField,
                            field : stkChartData.series.field,
                            color: stkChartData.series.color,
                            tooltip : stkChartData.series.tooltip
                          }]"
                        :category-axis="{
                          justified: true,
                        }"
                        :value-axis="[stkChartData.valueAxis]"
                        :legend-visible="false"
                        :theme="'sass'">
            </kendo-chart>
        </div>
      </div>
    </div> 
    </div>   


  <div class="box_flex mgt10">
     <div class="contents_wrap w20">     
      <div class="small_title_wrap">
        <h2>품목별 상품 비율</h2>
      </div>
          <div class="box_graph" style="width:100%; height:100%">
            <div class="inner-content">
              <span v-if="goodsChartData.totalCnt > 0" class="s_txt" style="top:30%">총 재고</span>
              <span v-if="goodsChartData.totalCnt > 0" class="txt_per" style="top:35%">{{ goodsChartData.totalCnt | comma }}EA</span>
            </div>
            <kendo-chart
                :data-source="goodsChartData.reportItemGoodsPercentResponseList"
                :class="'item_graph'"
                :series="[{ type: 'donut', field : 'cnt', holeSize: 70,}]"
                :tooltip="{ visible : goodsChartData.totalCnt !== 0, template : `#= dataItem.goodsNm # : #= kendo.format('{0:N0}', dataItem.cnt) # EA&nbsp` }"
                :chart-area="{background : '', height:300, margin: { top : -10, left : -10, right :-10, bottom:-10 }}"
                :legend-visible="false"
                :theme="'sass'">
            </kendo-chart>
          </div>
    </div>
    <div class="contents_wrap w30 mg0">     
      <div class="small_title_wrap">
          <h2>품목별 상품 출고순위</h2>
      </div>
        <div>
          <div>
            <kendo-chart :render-as="'canvas'"
                         :chart-area="{background : '', height:330, margin: { top : 10, left : 10, right :20, bottom:10 }}"
                         :pannable-lock="'y'"
                         :series="[{
                            type: outRankChartData.series.type,
                            data: outRankChartData.series.data,
                            axis: outRankChartData.series.axis,
                            visible : outRankChartData.series.visible,
                            categoryField: outRankChartData.series.categoryField,
                            field : outRankChartData.series.field,
                            color: outRankChartData.series.color,
                            tooltip : outRankChartData.series.tooltip
                          }]"
                         :category-axis="{justified: true}"
                         :value-axis="[outRankChartData.valueAxis]"
                         :legend="{visible:true}"
                         :theme="'sass'">
            </kendo-chart>
          </div>
        </div>
    </div>
    <div class="contents_wrap w50 mgl10">
     <div class="small_title_wrap">
        <h2>
          상품 재고현황 <button @click="$refs.stkItemListWindowRef.kendoWidget().center().open()" class="mid_btn orange">일별 수불현황</button>
        </h2>
      </div>
      <kendo-grid
          class="mgt20 w50 f_r"
          :height="330"
          :sortable-mode="'multiple'"
          :navigatable="false"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :selectable="false"
          :data-source="renewStkStatDataSource"
          :columns="renewGridColumns"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      ></kendo-grid>
      <kendo-grid
          class="mgt20 w50 f_r"
          :height="330"
          :sortable-mode="'multiple'"
          :navigatable="false"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :selectable="false"
          :data-source="newStkStatDataSource"
          :columns="newGridColumns"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      ></kendo-grid>
      
    </div>
    </div>
    <kendo-window
        ref="stkItemListWindowRef"
        :title="'일별 수불현황'"
        :modal="true"
        style="display:none; height: 720px; min-width: 1500px"
        :width="1700"
        @open="e=>{ isWindowOpen = true }"
        @close="e=>{ isWindowOpen = false }"
    >
      <component
          :is="isWindowOpen ? 'StkItemList' : null"
          :default-cmpy-cd="propsCmpyCd"
      ></component>
    </kendo-window>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import StkItemList from '@/views/report/item/StkItemList'
import Constant from '@/common/constant'

export default {
  name: 'ProductItemList',
  components : {
    StkItemList
  },
  methods: {
    onClickLegend : function (e){
      const vm = this
      vm.inTransitions = false
      if(e.series.axis === vm.constant.inRet){
        vm.inRetChartData.series.visible = !vm.inRetChartData.series.visible
        vm.$set(vm.inRetChartData.valueAxis, 'color', vm.inRetChartData.series.visible ?'' :'#d0d0d0')
      }else {
        vm.inNewChartData.series.visible = !vm.inNewChartData.series.visible
        vm.$set(vm.inNewChartData.valueAxis, 'color', vm.inNewChartData.series.visible ?'' :'#d0d0d0')
      }
    },
    getStkStat : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/item/stk-stat`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.newStkStatDataSource = response.data.resultData.reportItemNewStkStatTotalResponseList
        vm.renewStkStatDataSource = response.data.resultData.reportItemRenewStkStatTotalResponseList
      })
    },
    getOutRank : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/item/out-rank`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.setOutRankChartData(response.data.resultData)
      })
    },
    setOutRankChartData : function (responseChartData){
      const vm = this
      let resultChartData = _.cloneDeep(responseChartData)

      let max = 0
      let min = 99999
      let maxMinGapCnt = 0
      let majorUnit = 0

      if(resultChartData.length > 0){
        // 차트의 구분선을 5개로 변경하기위한 코드
        let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt > current.cnt) ? prev : current}).cnt
        let minCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt < current.cnt) ? prev : current}).cnt
        maxMinGapCnt =  maxCnt - minCnt
        let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
        // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
        max = Math.ceil(maxCnt * 1.001 / num) * num
        min = Math.floor(minCnt * 0.999 / num) * num
        // 구분선의 개수를 5로 지정(5칸)
        majorUnit = (max - min) / 5
        // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
        if(maxCnt < 5){
          max = 5
          min = 0
          majorUnit = 1
        }
      }

      vm.outRankChartData  = {
        series : {
          type: 'bar',
          data: resultChartData,
          visible : true,
          categoryField: 'goodsNm',
          field : 'cnt',
          tooltip : {
            visible: true,
            background : '#ffffff',
            template : `#= kendo.format('{0:N0}', value) #개 &nbsp`
          }
        },
        valueAxis : {
          visible: true,
          min : min,
          max : max,
          majorUnit : majorUnit,
          labels : {
            position : 'end',
            template : `#= kendo.format('{0:N0}', value) # 개 `
          }
        }
      }
    },
    getGoodsPercentCnt: function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/item/goods-percent`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        if(response.data.resultData.totalCnt === 0){
          response.data.resultData.reportItemGoodsPercentResponseList.push({ goodsNm : '데이터없음', cnt : 1, color : '#efefef'})
        }
        vm.goodsChartData = response.data.resultData

      })
    },
    // 출고현황(그래프)
    getChartFlow: function (){
      const vm = this
      vm.inTransitions = true

      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/item/stk-flow`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }

        vm.setInChartData(response.data.resultData.reportItemInCntResponse)
        vm.setOutChartData(response.data.resultData.reportItemOutCntResponseList)
        vm.setStkChartData(response.data.resultData.reportItemStkFlowResponseList)


      })
    },
    setInChartData : function (responseChartData){
      const vm = this

      for(let chartKey in responseChartData){
        let resultChartData = _.cloneDeep(responseChartData[chartKey])

        resultChartData.forEach((item, i) => {
          let isChangeMonth = false
          if(i === 0 || item.dt.split('/')[1] !== responseChartData[chartKey][i-1]?.dt?.split('/')[1]){
            isChangeMonth = true
          }
          if(isChangeMonth){
            item.category = Number(item.dt.split('/')[1]) + '/' + Number(item.dt.split('/')[2])
          }else {
            item.category = Number(item.dt.split('/')[2])
          }
          item.MMdd = item.dt.split('/')[1] + '/' + item.dt.split('/')[2]
        });

        let max = 0
        let min = 99999
        let maxMinGapCnt = 0
        let majorUnit = 0

        if(resultChartData.length > 0){
          // 차트의 구분선을 5개로 변경하기위한 코드
          let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt > current.cnt) ? prev : current}).cnt
          let minCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt < current.cnt) ? prev : current}).cnt
          maxMinGapCnt =  maxCnt - minCnt
          let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
          // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
          max = Math.ceil(maxCnt * 1.001 / num) * num
          min = Math.floor(minCnt * 0.999 / num) * num
          // 구분선의 개수를 5로 지정(5칸)
          majorUnit = (max - min) / 5
          // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
          if(maxCnt < 5){
            max = 5
            min = 0
            majorUnit = 1
          }
        }

        switch (chartKey){
          case 'reportItemInNewCntResponseGroupList':
            vm.inNewChartData = {
              series : {
                type: 'column',
                data: resultChartData,
                axis: vm.constant.inNew,
                visible : true,
                categoryField: 'category',
                field : 'cnt',
                color: '#b7c3ff',
                tooltip : {
                  visible: true,
                  background : '#ffffff',
                  template : `#= dataItem.MMdd # : 신품 #= kendo.format('{0:N0}', value) #개 &nbsp`
                }
              },
              valueAxis : {
                name: vm.constant.inNew,
                visible: true,
                min : 0,
                max : max,
                majorUnit : majorUnit,
                labels : {
                  position : 'end',
                  template : `#= kendo.format('{0:N0}', value) # 개 `
                }
              }
            }
            break
          case 'reportItemInReqCntResponseGroupList':
            vm.inRetChartData = {
              series : {
                type: 'line',
                data: resultChartData,
                axis: vm.constant.inRet,
                visible : true,
                categoryField: 'category',
                field : 'cnt',
                color: '#4b79d8',
                tooltip : {
                  visible: true,
                  background : '#ffffff',
                  template : `#= dataItem.MMdd # : 회수 #= kendo.format('{0:N0}', value) #개 &nbsp`
                }
              },
              valueAxis : {
                name: vm.constant.inRet,
                visible: true,
                min : 0,
                max : max,
                majorUnit : majorUnit,
                labels : {
                  position : 'start',
                  template : `#= kendo.format('{0:N0}', value) # 개 `
                }
              }
            }
            break
        }
      }
    },
    setOutChartData : function (responseChartData){
      const vm = this
      let resultChartData = _.cloneDeep(responseChartData)

      resultChartData.forEach((item, i) => {
        let isChangeMonth = false
        if(i === 0 || item.dt.split('/')[1] !== responseChartData[i-1]?.dt?.split('/')[1]){
          isChangeMonth = true
        }
        if(isChangeMonth){
          item.category = Number(item.dt.split('/')[1]) + '/' + Number(item.dt.split('/')[2])
        }else {
          item.category = Number(item.dt.split('/')[2])
        }
        item.MMdd = item.dt.split('/')[1] + '/' + item.dt.split('/')[2]
      });

      let max = 0
      let min = 99999
      let maxMinGapCnt = 0
      let majorUnit = 0

      if(resultChartData.length > 0){
        // 차트의 구분선을 5개로 변경하기위한 코드
        let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt > current.cnt) ? prev : current}).cnt
        let minCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt < current.cnt) ? prev : current}).cnt
        maxMinGapCnt =  maxCnt - minCnt
        let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
        // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
        max = Math.ceil(maxCnt * 1.001 / num) * num
        min = Math.floor(minCnt * 0.999 / num) * num
        // 구분선의 개수를 5로 지정(5칸)
        majorUnit = (max - min) / 5
        // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
        if(maxCnt < 5){
          max = 5
          min = 0
          majorUnit = 1
        }
      }
      vm.outChartData = {
        series : {
          type: 'line',
          data: resultChartData,
          axis: vm.constant.inRet,
          visible : true,
          categoryField: 'category',
          field : 'cnt',
          tooltip : {
            visible: true,
            background : '#ffffff',
            template : `#= dataItem.MMdd # : #= kendo.format('{0:N0}', value) #개 &nbsp`
          }
        },
        valueAxis : {
          name: vm.constant.inRet,
          visible: true,
          min : min,
          max : max,
          majorUnit : majorUnit,
          labels : {
            position : 'start',
            template : `#= kendo.format('{0:N0}', value) # 개 `
          }
        }
      }
    },
    setStkChartData : function (responseChartData){
      const vm = this
      let resultChartData = _.cloneDeep(responseChartData)

      resultChartData.forEach((item, i) => {
        let isChangeMonth = false
        if(i === 0 || item.dt.split('/')[1] !== responseChartData[i-1]?.dt?.split('/')[1]){
          isChangeMonth = true
        }
        if(isChangeMonth){
          item.category = Number(item.dt.split('/')[1]) + '/' + Number(item.dt.split('/')[2])
        }else {
          item.category = Number(item.dt.split('/')[2])
        }
        item.MMdd = item.dt.split('/')[1] + '/' + item.dt.split('/')[2]
      });

      let max = 0
      let min = 99999
      let maxMinGapCnt = 0
      let majorUnit = 0

      if(resultChartData.length > 0){
        // 차트의 구분선을 5개로 변경하기위한 코드
        let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt > current.cnt) ? prev : current}).cnt
        let minCnt = resultChartData.reduce(function(prev, current) {return (prev.cnt < current.cnt) ? prev : current}).cnt
        maxMinGapCnt =  maxCnt - minCnt
        let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
        // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
        max = Math.ceil(maxCnt * 1.001 / num) * num
        min = Math.floor(minCnt * 0.999 / num) * num
        // 구분선의 개수를 5로 지정(5칸)
        majorUnit = (max - min) / 5
        // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
        if(maxCnt < 5){
          max = 5
          min = 0
          majorUnit = 1
        }
      }
      vm.stkChartData = {
        series : {
          type: 'line',
          data: resultChartData,
          axis: vm.constant.inRet,
          visible : true,
          categoryField: 'category',
          field : 'cnt',
          color : '#008b14',
          tooltip : {
            visible: true,
            background : '#ffffff',
            template : `#= dataItem.MMdd # : #= kendo.format('{0:N0}', value) #개 &nbsp`
          }
        },
        valueAxis : {
          name: vm.constant.inRet,
          visible: true,
          min : min,
          max : max,
          majorUnit : majorUnit,
          labels : {
            position : 'start',
            template : `#= kendo.format('{0:N0}', value) # 개 `
          }
        }
      }

    },
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 조회일, 품목,
       * */
      refs.cmpyCdRef?.widget().value('')
      refs.dtRef.kendoWidget().value(new Date())
      refs.itemCdRef?.widget().value('')
      this.propsCmpyCd = ''
      this.allApiCall()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //회사명
      const cmpyCd = refs.cmpyCdRef?.widget().value()
      if (cmpyCd) {searchFilterOptions.searchCmpyCd = cmpyCd}

      //조회일
      const dt = refs.dtRef?.kendoWidget().value()
      if (!!dt) {
        searchFilterOptions.searchYYYYMMDD = EtnersDateTimeUtil.dateToYYYYMMDD(dt)
      }

      //품목
      const itemCd = refs.itemCdRef?.widget().value()
      if (itemCd) {searchFilterOptions.searchItemCd = itemCd}

      return searchFilterOptions ?? undefined
    },
    allApiCall: function () {
      const vm = this
      vm.getChartFlow()
      vm.getGoodsPercentCnt()
      vm.getOutRank()
      vm.getStkStat()
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const constant = {
      complete: '완료',
      req: '신청',
      expect: '예정',
      new: '신품',
      renew: '재생',
      outComplete: '출고완료',
      outReq: '출고신청',
      day: 'DAY',
      month: 'MONTH',
      inNew: '신품입고',
      inRet: '회수',
      outStatus: {
        req: '02',
        complete: '03',
        cancel: '04'
      },
      renewGbn: {
        new: '01',
        renew: '02'
      }
    }
    return {
      Constant,
      constant : constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      isWindowOpen : false,
      propsCmpyCd : '',
      inTransitions : true,
      inRetChartData : {
        series : {},
        valueAxis : {}
      },
      inNewChartData : {
        series : {},
        valueAxis : {}
      },
      outChartData : {
        series : {},
        valueAxis : {}
      },
      stkChartData : {
        series : {},
        valueAxis : {}
      },
      goodsChartData : {
        totalCount : 0
      },
      outRankChartData : {
        series : {},
        valueAxis : {}
      },
      newStkStatDataSource : [],
      renewStkStatDataSource : [],
      inType: 'inNew',
      newGridColumns:[
          { title: "상품(신규) 재고 현황",
            width: "7%",
            headerAttributes : {'class': 'bd_r f_b',},
            columns : [
              {
                field: 'goodsNm',
                title: '상품명',
                width: '',
                headerAttributes: headerAttributes,
              },
              {
                field: 'stkCnt',
                title: '현재재고',
                width: '20%',
                headerAttributes: headerAttributes,
                attributes: rightAttributes,
                template: function (dataItem) {
                  return `${EtnersCommonUtil.numberWithCommas(dataItem.stkCnt)}`
                }
              },
              {
                field: 'safeCnt',
                title: '안전재고',
                width: '20%',
                headerAttributes: headerAttributes,
                attributes: rightAttributes,
                template: function (dataItem) {
                  return `${EtnersCommonUtil.numberWithCommas(dataItem.safeCnt)}`
                }
              },
              {
                field: 'stkGradeNm',
                title: '재고등급',
                width: '20%',
                headerAttributes: headerAttributes,
                attributes: centerAttributes,
              }
              ]
          }],
      renewGridColumns:[
        { title: "상품(재생) 재고 현황",
          width: "7%",
          headerAttributes : {'class': 'bd_r f_b',},
          columns : [
            {
              field: 'goodsNm',
              title: '상품명',
              width: '',
              headerAttributes: headerAttributes,
            },
            {
              field: 'stkCnt',
              title: '현재재고',
              width: '20%',
              headerAttributes: headerAttributes,
              attributes: rightAttributes,
              template: function (dataItem) {
                return `${EtnersCommonUtil.numberWithCommas(dataItem.stkCnt)}`
              }
            },
            {
              field: 'safeCnt',
              title: '안전재고',
              width: '20%',
              headerAttributes: headerAttributes,
              attributes: rightAttributes,
              template: function (dataItem) {
                return `${EtnersCommonUtil.numberWithCommas(dataItem.safeCnt)}`
              }
            },
            {
              field: 'stkGradeNm',
              title: '재고등급',
              width: '20%',
              headerAttributes: headerAttributes,
              attributes: centerAttributes,
            }
          ]
        }],
      chartColor : [
        '#81ECEC'
        ,'#19ba68'
        ,'#74B9FF'
        ,'#FAB1A0'
        ,'#FF7675'
        ,'#A29BFE'
        ,'#FD79A8'
        ,'#00B894'
        ,'#FDCB6E'
        ,'#00CEC9'
        ,'#E17055'
        ,'#0984E3'
        ,'#D63031'
        ,'#6C5CE7'
        ,'#E84393'
      ],
    }
  },
}
</script>
