<template>
  <div>
    <!-- 타이틀  -->
    <div class="title_wrap">
      <h1>품목별 재고현황</h1>
    </div>
    <!-- 검색 영역 -->
    <common-area-search
        :row="3"
        :colspan="2"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              :value="$props.defaultCmpyCd"
              @change="value=>{
                propsCmpyCd = value
                commonOnChangeEvent()
                if(value === '' || !value){
                  stkItemDataSource = []
                }
              }"
              :params="{searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY]}"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>품목</label>
        <ul>
          <common-multi-drop-down
              ref="itemCdRef"
              :params="{ searchCmpyCd : propsCmpyCd }"
              :disabled="!propsCmpyCd || propsCmpyCd === ''"
              :all-params-validator="true"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/item`"
              :data-value-field="'itemCd'"
              :data-text-field="'itemNm'"
              @change="value=>{
                propsItemCd = value
                commonOnChangeEvent()
              }"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>기간</label>
        <ul>
          <common-range-datepicker
              ref="dtRef"
              :range="{start : weekAgoDate, end : new Date()}"
              @change="commonOnChangeEvent"
          >
          </common-range-datepicker>
        </ul>
      </template>
      <template v-slot:R2_C2>
        <label>상품</label>
        <ul>
          <common-multi-drop-down
              ref="goodsNmRef"
              @change="commonOnChangeEvent"
              :params="{ searchCmpyCd : propsCmpyCd, searchItemCd : propsItemCd }"
              :disabled="!propsCmpyCd || propsCmpyCd === ''"
              :all-params-validator="true"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/goods`"
              :data-value-field="'goodsCd'"
              :data-text-field="'goodsNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R3_C1>
        <label>상품구분</label>
        <ul>
          <common-button-group
              ref="searchRenewGbnRef"
              :style="'width: 100%; border: 0;'"
              :data-items="$store.state.codeRequestStore.search.combobox.renewGbn.data"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
              :default-value="$route.params.renewGbn"
              @click="value=>{commonOnChangeEvent(), visibleColumns(value)}"
          >
          </common-button-group>
        </ul>
      </template>
      <template #buttonArea>
        <div class="button_wrap_area" style="width:15% !important" >
          <ul>
            <li>
              <button class="large_btn orange" @click="commonOnChangeEvent">검색</button>
            </li>
            <li>
              <button class="large_btn" @click="cleanSearchDataEvent">초기화</button>
            </li>
          </ul>
        </div>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fr">
          <button class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
        </div>
      </div>

      <div class="content_list">
        <kendo-grid ref="gridRef"
                    style="cursor: pointer; max-height:400px"
                    :data-source="stkItemDataSource"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable="false"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="itemStkGridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
        />
      </div>
    </section>
  </div>
</template>

<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'
import Constant from '@/common/constant'

export default {
  name: 'StkItemList',
  props : {
    defaultCmpyCd : {
      type : String,
      required : false,
      default: ''
    }
  },
  created: function () {
    const vm = this
    vm.propsCmpyCd = vm.$props.defaultCmpyCd
    vm.getItemCdList()
  },
  methods: {
    startChange: function (e) {
      let start = kendo.jQuery("#start").data("kendoDatePicker");
      let end = kendo.jQuery("#end").data("kendoDatePicker");
      let startDate = start.value(),
          endDate = end.value();
      if (startDate) {
        startDate = new Date(startDate);
        startDate.setDate(startDate.getDate());
        end.min(startDate);
        end.max(new Date());
      } else if (endDate) {
        start.max(new Date(endDate));
        end.max(new Date());
      } else {
        endDate = new Date();
        start.max(endDate);
        end.min(endDate);
        end.max(new Date());
      }
    },
    endChange: function (e) {
      let start = kendo.jQuery("#start").data("kendoDatePicker");
      let end = kendo.jQuery("#end").data("kendoDatePicker");
      let endDate = end.value()
      let startDate = start.value();
      if (endDate) {
        endDate = new Date(endDate);
        endDate.setDate(endDate.getDate());
        start.max(endDate);
        end.max(new Date());
      } else if (startDate) {
        end.min(new Date(startDate));
        end.max(new Date());
      } else {
        endDate = new Date();
        start.max(endDate);
        end.min(endDate);
        end.max(new Date());
      }
    },

    getItemCdList: function () {
      const vm = this

      let param = vm.searchFilterParam()
      if(!param || !vm.propsCmpyCd){
        vm.lastRouteParams = {}
        return []
      }else {
        vm.lastRouteParams = {
          ...param,
          linkCmpyCd : vm.propsCmpyCd,
          linkLocaCd : "0001"
        }
      }
      ApiUtil.query(`${ApiConfig.fmsDomain}/fms/stk`, vm.lastRouteParams)
      .then((response) => {
        if (
            response.data === undefined ||
            response.data.resultStatus === undefined
        ) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }

        if (response.data.resultStatus.messageCode === "4042") {
          vm.$refs.gridRef.kendoWidget().setDataSource(new kendo.data.DataSource())
          return false;
        }

        if (response.data.resultStatus.messageCode !== "2000") {
          vm.$refs.gridRef.kendoWidget().setDataSource(new kendo.data.DataSource())
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.stkItemDataSource = response.data.resultData
      })
    },
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 품목, 상품, 바코드상품포함여부, 등록일자
       * */
      this.searchItemCd = ''
      refs.itemCdRef.widget().value('')
      refs.goodsCdRef.widget().value('')
      refs.searchRenewGbnRef?.widget().select(0)
      let initDate= {start : this.weekAgoDate, end : new Date()}
      refs.dtRef?.widget().range(initDate)

      this.$refs.gridRef.kendoWidget().setDataSource(new kendo.data.DataSource())

      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}

      const vm = this
      const refs = this.$refs

      const itemCd = refs.itemCdRef?.widget().value()
      if (itemCd) {
        searchFilterOptions.searchItemCd = itemCd

        const goodsCd = refs.goodsNmRef?.widget().value()
        if (goodsCd) {searchFilterOptions.searchGoodsCd = goodsCd}
      }

      const dtRefValue = refs.dtRef?.widget()?.range()
      if (dtRefValue) {
        const searchStartDt = dtRefValue.start
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.start) : ''
        const searchEndDt = dtRefValue.end
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.end) : ''
        if(!searchStartDt || !searchEndDt){
          return null
        }
        searchFilterOptions.searchStartDt = searchStartDt
        searchFilterOptions.searchEndDt = searchEndDt
      }
      else { return null }

      const searchRenewGbn = refs.searchRenewGbnRef?.widget().select()
      if (searchRenewGbn) {
        searchFilterOptions.searchRenewGbn = searchRenewGbn
      }

      return searchFilterOptions ?? undefined
    },
    visibleColumns(value){
      const vm = this
      vm.itemStkGridColumns.every(item=>{
        if(item.field === 'new'){
          item.columns.every(column=>{
            if(column.field === 'inNewCnt'){
              column.hidden = value === '02'
              return false
            }
          })
          return false
        }else return true
      })
    },
    commonOnChangeEvent: function (e) {
      const vm = this
      vm.getItemCdList()
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.fmsDomain}/fms/stk`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let itemStkGridColumns = vm.itemStkGridColumns.map(item=>{
              item.headerCellOptions = { textAlign: 'center', borderLeft: '1px', borderRight: '1px' }
              item.width = '80px'
              if (item.columns){
                item.children = item.columns
                item.children.forEach((child, i)=>{
                  child.headerCellOptions = { textAlign: 'center', borderLeft: i === 0 ? '1px' : '' }
                  child.width = '50px'
                })
              }
              return item
            })

            saveExcel({
              data: resultData,
              fileName: '품목별재고목록_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: itemStkGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
  },
  data () {
    const headerAttributes = {
      style: 'font-weight: bold; text-align: center;',
    }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    let now = new Date()
    let weekAgo = new Date()
    weekAgo.setDate(weekAgo.getDate() - 6)
    return {
      Constant,
      ApiConfig : ApiConfig,
      endDt : null,
      propsCmpyCd : '',
      propsItemCd : '',
      lastRouteParams : {},
      itemStkGridColumns: [
        {
          field: 'dt',
          title: '일자',
          width: '8%',
          headerAttributes : {
             'class': 'bd_r f_b',
          },  
          attributes: centerAttributes,
        },
        {
          field: 'itemNm',
          title: '품목명',
          width: '18%',
          headerAttributes : { 'class': 'bd_r f_b', },
          attributes: centerAttributes,
        },
        {
          field: 'goodsNm',
          title: '상품명',
          width: '18%',
          headerAttributes : { 'class': 'bd_r f_b', },
          attributes: centerAttributes,
          template: function (dataItem) {
            return dataItem.goodsNm ? dataItem.goodsNm : '-'
          }
        },
        {
          field: "beginCnt",
          title: "기초",
          width: "6%",
          format: "{0:###,#}",
          headerAttributes : {
             'class': 'bd_r f_b',
          },  
                    attributes: rightAttributes
        },
        { field: "new",
          title: "입고",
          headerAttributes : {
             'class': 'bd_r f_b',
          },  
            columns : [
            { field: "inNewCnt",
              title: "신품",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "inExpCnt",
              title: "체험",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "inExchgCnt",
              title:"교환",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "inRentCnt",
              title:"대여",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "inAsCnt",
              title:"A/S",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "inTrnsCnt",
              title: "전환",
              format: "{0:###,#}",
              width: "6%",
               headerAttributes : {
             'class': 'bd_r f_b',
              },
              attributes: rightAttributes
            },
          ]
        },

        {
          title: "출고",
          width: "7%",
           headerAttributes : {
             'class': 'bd_r f_b',
          },  
          columns : [
            { field: "outSaleCnt",
              title: "판매",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes : headerAttributes,
              attributes: rightAttributes
            },
            { field: "outExpCnt",
              title:"체험",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "outExchgCnt",
              title:"교환",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "outRentCnt",
              title:"대여",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "outAsCnt",
              title:"A/S",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes: headerAttributes,
              attributes: rightAttributes
            },
            { field: "outTrnsCnt",
              title: "전환",
              format: "{0:###,#}",
              width: "6%",
              headerAttributes : {
                'class': 'bd_r f_b',
              },
              attributes: rightAttributes
            },
          ]
        },
        {
          field: "lossCnt",
          title: "분실",
          width: "6%",
          format: "{0:###,#}",
           headerAttributes : {
             'class': 'bd_r f_b',
          },  
          attributes: rightAttributes
        },
        {
          field: "disuseCnt",
          title: "폐기",
          width: "6%",
          format: "{0:###,#}",
           headerAttributes : {
             'class': 'bd_r f_b',
          },  
          attributes: rightAttributes,
        },
        {
          field: 'endCnt',
          title: '기말',
          width: '6%',
          format: "{0:###,#}",
          headerAttributes: headerAttributes,
          attributes: rightAttributes
        },
      ],
      weekAgoDate : weekAgo,
      stkItemDataSource : [],
      selectedStartDate : weekAgo,
      selectedEndDate : now,
      searchItemCd : null,
    }
  },
}
</script>
